<template>
	<div class="settlement">
		<!-- 订单详情 -->
		<div class="settlement-details flex">
			<div style="width:100%">
				<div class="settlement-details-top">
					<ul class="top-details">
						<li>
							<svg t="1597823933793" class="icon" viewBox="0 0 1024 1024" version="1.1"
								xmlns="http://www.w3.org/2000/svg" p-id="2221" width="16" height="16">
								<path
									d="M725.333333 597.333333H298.666667a170.666667 170.666667 0 0 0-170.666667 170.666667v128h768v-128a170.666667 170.666667 0 0 0-170.666667-170.666667zM213.333333 810.666667v-42.666667c0-47.061333 38.272-85.333333 85.333334-85.333333h426.666666c47.061333 0 85.333333 38.272 85.333334 85.333333v42.666667H213.333333zM512 128a213.333333 213.333333 0 1 0 0.042667 426.709333A213.333333 213.333333 0 0 0 512 128z m0 341.333333c-70.570667 0-128-57.429333-128-128s57.429333-128 128-128 128 57.429333 128 128-57.429333 128-128 128z"
									p-id="2222" />
							</svg>
							<p>联系人:</p>
							<p>
								<input type="text" v-model="user_name" placeholder="请输入您的真实姓名" />
							</p>
						</li>
						<li>
							<svg t="1597824701219" class="icon" viewBox="0 0 1024 1024" version="1.1"
								xmlns="http://www.w3.org/2000/svg" p-id="3836" width="16" height="16">
								<path
									d="M696.32 40.96H327.68a122.88 122.88 0 0 0-122.88 122.88v696.32a122.88 122.88 0 0 0 122.88 122.88h368.64a122.88 122.88 0 0 0 122.88-122.88V163.84a122.88 122.88 0 0 0-122.88-122.88z m81.92 819.2a81.92 81.92 0 0 1-81.92 81.92H327.68a81.92 81.92 0 0 1-81.92-81.92V163.84a81.92 81.92 0 0 1 81.92-81.92h368.64a81.92 81.92 0 0 1 81.92 81.92z"
									p-id="3837" />
								<path
									d="M225.28 778.24h573.44v40.96H225.28zM471.04 860.16h81.92v40.96h-81.92zM225.28 204.8h471.04v40.96H225.28zM737.28 204.8h61.44v40.96h-61.44z"
									p-id="3838" />
							</svg>
							<p>手机号:</p>
							<p>
								<input type="number" v-model="user_phone" placeholder="请输入您的手机号" />
							</p>
						</li>
					</ul>
				</div>

				<div class="settlement-details-content" v-if="Object.keys(orderInfo).length > 0">
					<div class="window-wrap-top">
						<div>
							<img :src="orderInfo.goods_lists.goods_imgurl" alt />
						</div>
						<div>
							<p class="font-text">{{ orderInfo.goods_lists.goods_name }}</p>
							<p v-if="orderInfo.goods_lists.specs_types == 2">{{ orderInfo.goods_lists.specs_name }}</p>
							<div>
								<p>￥{{ orderInfo.goods_lists.goods_price }}</p>
								<span>x {{ orderInfo.goods_lists.goods_nums }}</span>
							</div>
						</div>
					</div>
				</div>

				<ul class="settlement-details-methods">
					<li>
						<p>商品金额</p>
						<p class="settlement-price">￥{{ orderInfo.goods_amount }}</p>
					</li>
					<li>
						<p>支付方式</p>
						<div>
							<p>通联支付</p>
						</div>
					</li>
				</ul>
				<div class="agree-row">
					<div :class="['check', { active: isCheck }]" @click="changeCheck"></div>
					<span @click="changeCheck">我已阅读并同意</span>
					<a @click="showCover = true">《平台用户服务协议》</a>
				</div>
			</div>
			<div class="bot-box">
				<div class="agree-row tip-box">
					<span class="tips"></span>
					<span>购买即视为认同产品使用规则，无正当理由不可退改，如遇使用问题可咨询客服。</span>
				</div>
				<div class="footer-bottom">
					<p @click="toPostPay">立即支付</p>
				</div>
			</div>
		</div>
		<van-overlay :show="showCover" @click="showCover = false" :lock-scroll="false">
			<div class="wrapper" @click.stop>
				<apply-promoter types="5" :isChangeTitle="false"></apply-promoter>
			</div>
		</van-overlay>
	</div>
</template>


<script>
	import {
		cardsPreparePay,
		cardsPayOrder,
		imitateOrderCardsPay,
		closeCardsOrder
	} from "@/http/api";
	
	import ApplyPromoter from "@/views/my/change/applypromoter";
	
	import {
		chooseWXPay
	} from "@/common/wxJssdk";

	export default {
		components: {
			ApplyPromoter
		},
		data() {
			return {
				userToken: "",
				id: 0,
				share_id: 0,
				referrer_id: 0, 
				pay_types: 3, //支付方式
				orderInfo: {},
				user_name: "",
				user_phone: "",
				isCheck: false,
				showCover: false,
				isPost: false, 
			};
		},
		mounted() {
			let route = this.$route.query;
			this.userToken = this.$LStorage.getItem("userToken").user_token;
			if (route.id) {
				this.id = route.id;
				this.share_id = route.share_id ? route.share_id : 0;
				this.referrer_id = route.referrer_id ? route.referrer_id : 0;
				this.cardsPreparePay();
			} else {
				this.$dialog.alert({
					message: '订单预结算信息获取错误, 点击跳转到首页看看',
				}).then(() => {
					this.$router.replace({
						path: "/home"
					});
				});
			} 
		},
		methods: {
			// 选择协议
			changeCheck() {
				this.isCheck = !this.isCheck;
			},

			//  订单信息
			async cardsPreparePay() {
				const res = await cardsPreparePay({
					data: {
						user_token: this.userToken,
						buffer_id: this.id
					}
				});
				this.orderInfo = res.data;
			},

			// 点击支付按钮
			toPostPay() {
				if (this.isPost) {
					this.$toast("请勿重复提交");
				} else {
					this.SubmitPay();
				}
			},

			//  立即支付
			async SubmitPay() {
				if (this.user_name.trim().length == 0) {
					this.$toast("请正确输入联系人的姓名");
					return false;
				}

				if (!/^1[3456789]\d{9}$/.test(this.user_phone.trim())) {
					this.$toast("请正确输入您的手机号");
					return false;
				}

				if (!this.isCheck) {
					this.$toast("请同意协议");
					return false;
				}
				this.isPost = true;
				const res = await cardsPayOrder({
					data: {
						user_token: this.userToken,
						site_code: this.$LStorage.getItem("curSiteInfo").site_code,
						buffer_id: this.id,
						pay_types: this.pay_types,
						referrer_id: this.referrer_id,
						share_id: this.share_id,
						address_contacts: this.user_name,
						address_phone: this.user_phone
					}
				});
				if (res.code == 200) {
					this.toPay(res.data);
					//this.imitateOrderCardsPay(res.data); 
				} else {
					this.$toast(res.msgs);
				}
			},

			// 模拟支付
			async imitateOrderCardsPay(data) {
				const res = await imitateOrderCardsPay({
					data: {
						user_token: this.userToken,
						order_id: data.payIds.order_id,
						referrer_id: this.referrer_id,
						total_fee: 1
					}
				});
				console.log(res);
				this.isPost = false;
				if (res.code == "200") {
					this.$router.replace({
						path: "/result"
					});
				} else {
					this.$toast(res.msgs);
				}
			},

			// 发起支付
			toPay(datas) {
				let that = this;
				if (that.pay_types == 1) {
					chooseWXPay(datas.payInfo, res => {
						if (res) {
							this.$router.replace({
								path: "/result"
							});
						} else {
							// that.closeOrder(datas)
						}
					});
				} else if (that.pay_types == 3) {
					console.log(datas);
					window.location.href = datas.payInfo;
				}
			},

			// 发起支付
			onBridgeReady(data) {
				var that = this,
					payInfo = data.payInfo;
				WeixinJSBridge.invoke(
					"getBrandWCPayRequest", {
						appId: payInfo.appId, //公众号名称，由商户传入
						timeStamp: payInfo.timeStamp, //时间戳，自1970年以来的秒数
						nonceStr: payInfo.nonceStr, //随机串
						package: payInfo.package,
						signType: payInfo.signType, //微信签名方式：
						paySign: payInfo.paySign //微信签名
					},
					function(response) {
						this.isPost = false;
						console.log(response);
						if (response.err_msg.indexOf("ok") > -1) {
							// 支付成功
							that.$router.replace({
								path: "/result"
							});
						} else if (response.err_msg === "get_brand_wcpay_request:cancel") {
							// 取消支付
							that.$toast.fail("取消支付");
							//this.closeOrder(data)
						} else {
							// 支付错误
							that.$toast.fail("支付失败");
							//this.closeOrder(data)
						}
					}
				);
			},
			
			// 取消订单
			async closeOrder(data) {
				const res = await closeCardsOrder({
					data: {
						user_token: this.userToken,
						order_id: data.payIds.order_id
					}
				});
			}
		},
		created() {
			// document.title = "订单结算";
		}
	};
</script>


<style lang="scss" scoped>
	.settlement {
		width: 100%;

		.settlement-details {
			width: 100%;
			height: 100vh;
			background: #f6f6f6;
			flex-direction: column;
			justify-content: space-between;

			.settlement-details-top {
				width: 100%;
				// height: 2.1rem;
				background: #fff;

				.top-details {
					width: calc(100% - 0.48rem);
					margin: 0 auto;

					li {
						padding-top: 0.41rem;
						padding-left: 0.27rem;
						padding-bottom: 0.36rem;
						border-bottom: 1px solid #f2f2f2;
						box-sizing: border-box;
						display: flex;
						font-size: 0.28rem;

						p {
							margin-left: 0.16rem;
						}

						.icon {
							margin-top: 0.05rem;
						}
					}

					li:last-child {
						border-bottom: none;
					}
				}
			}

			.settlement-details-content {
				/* width: calc(100% - 0.48rem); */
				width: 100%;
				margin: 0 auto;
				margin-top: 0.47rem;
				margin-bottom: 0.33rem;
				background: #fff;
				border-radius: 0.1rem;
				padding: 0.14rem 0.09rem 0.14rem 0.09rem;
				box-sizing: border-box;

				.window-wrap-top {
					display: flex;

					div:nth-child(1) {
						img {
							width: 2.45rem;
							height: 1.8rem;
							object-fit: cover;
						}
					}

					div:nth-child(2) {
						width: 100%;
						margin-left: 0.19rem;
						padding-right: 0.23rem;
						box-sizing: border-box;

						.font-text {
							width: 4.21rem;
							font-size: 0.26rem;
							margin-top: 0.1rem;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							color: #767676;
						}

						p:nth-child(2) {
							width: max-content;
							height: 0.46rem;
							text-align: center;
							line-height: 0.46rem;
							background: #fce0e0;
							border-radius: 0.1rem;
							font-size: 0.23rem;
							margin-top: 0.21rem;
							padding: 0 0.12rem;
						}
					}

					div:nth-child(3) {
						display: flex;
						margin-top: 0.25rem;
						justify-content: space-between;
						width: 100%;
					}

					div:nth-child(3) p:nth-child(1) {
						font-size: 0.23rem;
						color: #e5232f;
					}

					div:nth-child(3) span {
						display: block;
						font-size: 0.23rem;
					}
				}
			}

			.settlement-details-methods {
				/* width: calc(100% - 0.48rem); */
				width: 100%;
				margin-top: 0.33rem;
				background: #fff;
				margin: 0 auto;
				border-radius: 0.1rem;

				li {
					width: 100%;
					height: 1.18rem;
					display: flex;
					justify-content: space-between;
					font-size: 0.27rem;
					border-bottom: 1px solid #f2f2f2;
					padding: 0.43rem 0.14rem 0.17rem 0.23rem;
					box-sizing: border-box;

					.settlement-price {
						color: #e62430;
					}
				}

				li:nth-child(2) {
					border-bottom: none;

					div {
						display: flex;
						width: 2.22rem;
						justify-content: space-between;
					}
				}
			}

			.agree-row {
				display: flex;
				align-items: center;
				// justify-content: center;
				color: #949494;
				font-size: 0.26rem;
				margin: 0.3rem;

				.check {
					width: 0.24rem;
					height: 0.24rem;
					background: url("../../assets/images/check.png") no-repeat center;
					background-size: cover;
					margin-right: 0.1rem;
				}

				.tips {
					background: url("../../assets/images/tip.png") no-repeat center;
					background-size: 100%;
					width: 0.25rem;
					height: 0.25rem;
					margin-right: 0.1rem;
				}

				.active {
					background-image: url("../../assets/images/checked.png");
				}

				a {
					color: #e50012;
				}
			}

			.bot-box {
				margin-bottom: 0.64rem;

				.tip-box {
					align-items: baseline;
				}

				.footer-bottom {
					width: calc(100% - 0.48rem);
					height: 0.84rem;
					margin: 0 auto;
					background: #e50012;
					text-align: center;
					line-height: 0.84rem;
					color: #fff;
					font-size: 0.3rem;
					border-radius: 0.42rem;
				}
			}
		}

		.wrapper {
			width: 80vw;
			height: 80vh;
			background: #fff;
			margin: 10vh auto;
			border-radius: 0.1rem;
			overflow-y: auto;
			padding: 0.24rem;
			box-sizing: border-box;

			.applypromoter {
				padding: 0;
			}
		}
	}
</style>
